import { WidenRecordDeep } from '@/common/utils/types';

type LocaleResourceBase = {
  [x: string]: string | LocaleResourceBase;
};

const en = {
  main: {
    navbar: {
      home: 'Home',
      customers: 'Customers',
      about: 'About',
      developers: 'Developers',
      language: 'Language',
    },
    hero: {
      topBadge: 'Global payments solutions',
      header: 'Send money anywhere',
      subHeading:
        'From dollars and euros to pesos and beyond, Sphere makes moving between currencies effortless.',
      ctas: {
        primary: 'For businesses',
        secondary: 'For individuals',
      },
    },
    solutions: {
      header: {
        topBadge: 'Optimized payment processing',
        header: 'Cross-border payments for businesses',
        subHeading:
          'Better money movement—from wire, bank transfer, and card to support for every major network—\ntransferring money over the internet has never been easier.',
        ctas: {
          primary: 'Get started',
          secondary: 'Learn more',
        },
        cards: {
          '0': {
            title: 'For international remittances',
            description:
              'Send your preferred currency, your vendors receive USD, MXN, BRL, EUR at world-class exchange rates.',
            bullets: {
              '0': 'Compliant & secure',
              '1': 'Near-instant settlement',
              '2': 'Global coverage',
            },
          },
          '1': {
            title: 'For high-net-worth individuals',
            description:
              'OTC services, effortless currency conversions, and comprehensive financial tracking and management solutions.',
            bullets: {
              '0': 'Dedicated support 24/7',
              '1': 'Deep liquidity ',
              '2': 'Privacy and encryption',
            },
          },
          '2': {
            title: 'For Finance & FinTechs',
            description:
              'Accept payments any way you want, leverage auto-conversions that settle directly to your bank, and offer conversion services to your customers with white-label solutions.',
            bullets: {
              '0': 'Service level agreements',
              '1': 'Customer guardrails',
              '2': 'API and No-Code Solutions',
            },
          },
        },
      },
    },
    ramp: {
      topBadge: 'Conversion solutions',
      header: 'Connecting the world’s currencies',
      subHeading:
        "Sphere handles the engineering, operational, and regulatory complexities for you — orchestrating so you don't have to.",
      solutions: {
        '0': {
          title: 'White-labeled solutions for better money movement',
          description:
            "Sphere handles the engineering, operational, and regulatory complexities for you — orchestrating so you don't have to.",
          bullets: {
            '0': 'Available by API, no-code, and embeddable widget.',
            '1': 'Save hundreds of hours on development time.',
            '2': 'Unrivaled pricing.',
          },
        },
        '1': {
          title: 'Currencies conversions made easy',
          description:
            'Generate a wallet tied to your bank account. Send currencies and receive USD with just one click. Available to select merchants through the Sphere dashboard.',
        },
        '2': {
          title: 'All-in-one compliance',
          description:
            'Rely on our global expertise and regulatory licenses to provide your users with the optimal payout experience. Allow us to handle transaction monitoring, sanction screening, and fraud detection on your behalf.',
        },
      },
    },
    dashboard: {
      topBadge: 'Features',
      header: 'Sphere dashboard',
      subHeading:
        'Sphere payment links are versatile, customizable and robust. They adapt to your needs, whether for one-time purchases or recurring subscriptions.',
      solutions: {
        '0': {
          title: 'Payment links',
          description:
            'Accept payments directly from email, SMS, social media, website, or app. Get the full power of Sphere without having to write a single line of code.',
        },
        '1': {
          title: 'Subscriptions',
          description:
            'Offer traditional or true on-chain subscriptions — directly from a card, bank account, or digital wallet. Enjoy flexible billing options, ranging from per-minute to annual, metered to per-seat, and volume to graduated tiers. Receive payments directly, without escrow, token unwrapping, gas relayers, or other unnecessary steps.',
        },
        '2': {
          title: 'Developer toolkit',
          description:
            'Sphere simplifies your engineering process with integrated payment features. We navigate global economic intricacies, allowing your teams to focus on seamless development on a single platform.',
        },
        '3': {
          title: 'Customer Insights and Analytics',
          description:
            'Track 15+ KPIs, from MRR and churn, to conversion and click-through rates.  Maximize earnings potential, increase authorization rates, get faster settlement, and reduce chargebacks by accepting more types of money. ',
        },
      },
    },
    developerTools: {
      topBadge: 'SDK',
      header: 'Developer toolkit',
      subHeading:
        'We follow the best practices in payments to deliver semantic, easy-to-use SDKs. Focus on growing your business while we handle smart contract audits, cross-chain orchestration, and all the technical, operational, and compliance upkeep.',
      bullets: {
        '0': 'Simple, powerful, and intuitive RESTful API',
        '1': 'Copy and paste code that just works',
        '2': 'Concise and thorough documentation',
      },
      ctas: {
        primary: 'Documentation',
        secondary: 'Developer support',
      },
    },
    features: {
      header: {
        topBadge: 'Product Features',
        title: 'An end-to-end payments suite',
        description:
          'Connect seamlessly through a single API or a user-friendly no-code console in just minutes.\nGain access to top-tier compliance, accounting, payments, on/off-ramps, analytics, and developer tools.',
      },
      grid: {
        '0': 'Comprehensive analytics',
        '1': 'Multiparty payments and payouts',
        '2': 'Build together with role-based ACLs',
        '3': 'AML & fraud detection',
        '4': 'Secured and certified',
        '5': 'Email notifications',
      },
      securityMarquees: {
        '0': 'OFAC\nsanctions',
        '1': 'FTC best practices',
        '2': 'GDPR best practices',
        '3': 'PCI\ncompliant',
      },
      benefits: {
        lowestFees: {
          title: 'Lowest Fees on the Market',
          description:
            'All transactions at a competitor matched fee, with network fees passed at cost.',
        },
        convenientSwaps: {
          title: 'Convenient Swaps',
          description:
            'Payouts directly to your or a third-party vendor’s bank account, either manually or on a scheduled basis, with competitive OTC rates.',
        },
        instantSettlements: {
          title: 'Instant Settlements',
          description:
            'Receive your money in seconds to minutes, instead of days to weeks.',
        },
        splitPayouts: {
          title: 'Split Payouts',
          description: 'Automatically split payments without extra fees.',
        },
        nonCustodial: {
          title: 'Non-Custodial',
          description:
            'Payments are peer to peer and we never handle customer or merchant funds.',
        },
        audited: {
          title: 'Audited',
          description:
            'Our contracts are audited by industry-leading experts. We regularly conduct threat modeling, penetration testing, and vulnerability assessments on our infrastructure.',
        },
        compliant: {
          title: 'Compliant',
          description:
            'Sphere follows all best practices according to US regulations and uses licensed money transmitters.',
        },
        encrypted: {
          title: 'Encrypted',
          description:
            'All data is encrypted and/or tokenized at rest and in transit. We never store sensitive PIIs, and have strict ACLs.',
        },
      },
    },
    ctaSection: {
      header: 'Ready to take the next step?',
      description: 'Join industry leaders growing with Sphere.',
      button: 'Get started',
    },
    faq: {
      header: 'FAQ',
      description:
        'Commonly asked questions. Can’t find the answer you’re looking for? Please get in touch.',
      questions: {
        '0': {
          q: 'How much does Sphere cost?',
          a: 'Software access is free, but we take a small transaction fee. Custom enterprise pricing is available upon request.',
        },
        '1': {
          q: 'Which networks are supported?',
          a: 'We support all major card networks (Visa, Mastercard, American Express, Discover) in 120+ countries, bank transfers, and domestic and international wires.',
        },
        '2': {
          q: 'How is currency conversion handled?',
          a: 'Customers consent to swapping to a different currency during transfer, which is routed through an exchange or subject to foreign exchange rates at cost.',
        },
        '3': {
          q: 'Is Sphere audited?',
          a: 'Yes, we are audited by OtterSec and PCI-DSS compliant. We volunteer for MSB/VASP requirements, and employ a robust KYC/AML program. We are in the process of receiving our SOC2 and ISO/IEC 27001 certifications.',
        },
        '4': {
          q: 'Does Sphere custody funds?',
          a: 'Never. We are a technology services provider, not a MSB/VASP nor money transmitter. All payments that involve custody are handled by licensed money transmitters, qualified custodians, and chartered banks.',
        },
        '5': {
          q: 'Is it possible to integrate Sphere with my existing payment solution?',
          a: 'Yes. You can use your own Stripe or other payment providers, and use Sphere to add support for alternative payments and back-office automation through our API/SDK. We designed our API to use similar data flows and object models to reduce the amount of infrastructure to rewrite.',
        },
      },
    },
    footer: {
      slogan: 'Accelerating digital value transfer for a more connected world.',
      termsOfService: 'Terms of Service',
      privacyPolicy: 'Privacy Policy',
      reportVulnerability: 'Report a Vulnerability',
    },
  },
  accessRequest: {
    steps: {
      '1': {
        title: 'Request Access',
        descriptionParagraph1:
          'For <0>individuals</0> looking to use our on/off ramp functionality, you can now do so without reaching out here. <0>Just visit </0><1>spherepay.co/ramp</1> to sign up and get started.',
        descriptionParagraph2:
          'For businesses or other inquiries, please fill out the information below and an expert from Sphere will be in touch shortly.',
        form: {
          walletAddress: 'Wallet Address',
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Work Email',
          businessName: 'Business Name',
          businessUrl: 'Business Website URL',
          phoneNumber: 'Phone Number',
        },
        notYourEmail: 'Not your email?',
        errors: {
          emailAlreadyInUse:
            'This email is already in use for an active submission. Please try another.',
          emailAlreadyLinkedToAccount:
            'This email is already linked to an account. Login instead?',
        },
      },
      '2': {
        title: 'Contact Information',
        form: {
          preferredContactMethod: 'Preferred Contact Method',
          preferredContactMethodDescription:
            'Account access emails will be delivered by email.',
          placeholders: {
            telegram: '@username',
            whatsapp: '+1234567890',
            email: 'you@example.com',
            sms: '+1234567890',
          },
        },
      },
      '3': {
        title: 'Business Details',
        form: {
          bankAccountJurisdiction:
            'Does your business have a bank account in any of the following jurisdictions?',
          bankAccountJurisdictionsOptions: {
            unitedStates: 'United States (USD)',
            europeanUnion: 'European Union (EUR)',
            noneOfTheAbove: 'None of the above',
          },
          preferredCurrencies:
            'Which currencies do you want to use for on-and off-ramp transactions?',
          preferredCurrenciesDescription: 'Please select at least one.',
          otherCurrencyPlaceholder: 'Please specify',
        },
      },
      '4': {
        title: 'Business Details',
        submitApplication: 'Submit application',
        form: {
          businessOperatingAddress: 'Business operating address',
          businessCountry: 'What country is your business located?',
          countryPlaceholder: 'Country',
          statePlaceholder: 'State',
          monthlyTransferVolume: 'Monthly transfer volume',
          estimatedTransactionVolume:
            'What was your estimated transaction volume from the last month?',
          selectPlaceholder: 'Please select',
          monthlyVolume: {
            '0_4999': 'Less than $4,999',
            '5000_9999': '$5,000 - $9,999',
            '10000_99999': '$10,000 - $99,999',
            '100000_499999': '$100,000 - $499,999',
            '500000_999999': '$500,000 - $999,999',
            '1000000_5000000': '$1,000,000 - $5,000,000',
            '5000000_plus': 'More than $5,000,000',
          },
          additionalDetails: 'Additional details',
          additionalDetailsDescription:
            'Describe the nature of the business and your goals for the account.',
        },
      },
    },
    submitted: {
      title: 'Your sign up has been\nsuccessfully submitted',
      defaultBody:
        "Thanks for indicating your interest. Our team will review your information and be in touch <0>via email</0> if we're able to extend you access to an account.",
      bridgeBody:
        'Thanks for indicating your interest. Our team will review your information and be in touch <0>via email</0> when we might be able to serve you.',
      conciergeBody:
        "Great! Someone from our team will be in touch shortly. If there's any delay, please feel free to reach out to us directly here:",
      returnToWebsite: 'Return to website',
      contactUs: 'Connect with your onboarding specialist',
    },
    googleSplash: {
      pendingTitle: 'Your account is still\npending approval',
      noAccountTitle: 'This Gmail is not associated\nwith a Sphere account',
      applicationId: 'Your Application ID:',
      pendingDescription:
        "We've received your Sphere application and are processing your request. We'll be in touch soon with an update on your status of your account as soon as possible.",
      noAccountDescription:
        "This Gmail isn't linked to a Sphere account.\nComplete an application to get started.",
      returnToLogin: 'Return to login',
      fillOutApplication: 'Fill out application',
    },
    loadingAnimation: {
      calculatingRates: 'Calculating the best exchange rates...',
      securingTransactions: 'Securing your transactions...',
      optimizingPayments: 'Optimizing global payments...',
      connectingCurrencies: 'Connecting currencies...',
      ensuringCompliance: 'Ensuring compliance standards...',
      processingData: 'Processing data securely...',
      almostThere: 'Almost there...',
    },
    passwordSetup: {
      loginSuccess: 'Login success! Welcome back {{email}}',
      newLinkSent: 'A new link has been sent to your email.',
      regenerateError:
        'An error occurred while regenerating the access token. Please try again.',
      tokenNotFoundOrInvalid: 'Token not found or invalid',
      tokenNotFoundOrInvalidDescription:
        "The access token you're trying to use is either not found or invalid. This might be due to expiration or an error in the link.",
      returnToWebsite: 'Return to website',
      contactSupport: 'Contact support',
      linkExpired: 'Your link has expired',
      linkExpiredDescription:
        'The email link has expired. Please generate a new one to complete your sign-up.',
      redirecting:
        "Redirecting you back {{count === 0 ? 'now' : count === 1 ? `in ${count} second` : `in ${count} seconds`}}...",
      regenerateLink: 'Regenerate link',
      createSignIn: 'Create Sign In',
      createAccount: 'Create account',
      email: 'Email',
      password: 'Password',
      enterPassword: 'Enter Password',
      confirmPassword: 'Confirm Password',
    },
  },
  validation: {
    required: 'Required',
    invalidEmail: 'Please enter a valid email',
    weakPassword:
      'Password must be at least 6 characters long and contain at least one letter and one number',
    passwordsDoNotMatch: 'Passwords do not match',
  },
  general: {
    comingSoon: 'Coming soon',
    support: 'Support',
    optional: 'Optional',
    back: 'Back',
    next: 'Next',
    continue: 'Continue',
    yes: 'Yes',
    no: 'No',
    other: 'Other',
    selectAllThatApply: 'Select all that apply',
    unknownError: 'An unknown error occurred. Please try again later.',
  },
} as const satisfies LocaleResourceBase;

export type LocaleResource = WidenRecordDeep<typeof en>;

export default en;
